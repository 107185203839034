import React, { useState, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { CommonButton } from '../../shared/CommonButton';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { RoundedButton } from '../../shared/RoundedButton';
import { Autocomplete, Stack, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import {
  setShowAddressForm,
  setShowAuthForm,
  setUnSavedAddress,
} from '../../app/userSlice';
import {
  addCustomerAddress,
  updateCustomerAddress,
} from '../../shared/libs/requests/user';
import { saveUserInfo } from '../../shared/libs/helpers';

import './index.scss';

export const HeaderRegionsForm = () => {
  const dispatch = useDispatch();
  const viewportHeight = window.innerHeight;
  const showAddressForm = useSelector((state) => state.user.showAddress);
  const [openDrawer, setOpenDrawer] = useState(showAddressForm);
  const [citiesList, setCitiesList] = useState([]);
  const [streetsList, setStreetsList] = useState([]);
  const [cityId, setCityId] = useState('');
  const [selectedCity, setSelectedCity] = useState(''); // Хранит выбранный город
  const [selectedAddress, setSelectedAddress] = useState(''); // Хранит выбранный адрес (улица)
  const userStore = useSelector((state) => state.user.user);
  console.log('showAddressForm', showAddressForm);
  const closeDrawer = () => {
    setCityId('');
    setCitiesList([]);
    setStreetsList([]);
    setSelectedCity('');
    setSelectedAddress('');
    dispatch(setShowAddressForm(null));
  };

  const toggleDrawer = (newOpen) => {
    setOpenDrawer(newOpen);
    if (!newOpen) closeDrawer();
  };

  const onApplyFilterBtnHandler = async () => {
    const data = {
      city: selectedCity,
      address_1: selectedAddress,
      postal_code: streetsList?.find((el) => el.address?.postcode).address
        ?.postcode,
      ...(!userStore?.addresses?.length
        ? {
            is_default_billing: true,
            is_default_shipping: true,
          }
        : null), // Добавляем дефолтный адрес только для самого первого адреса
    };

    if (userStore?.id) {
      try {
        if (showAddressForm === 'new') {
          console.log(userStore, showAddressForm);

          const customer = await addCustomerAddress(data);
          saveUserInfo(customer, dispatch);
        } else {
          console.log(userStore, showAddressForm);
          const customer = await updateCustomerAddress(
            getDefaultAddress()?.id,
            data
          );
          saveUserInfo(customer, dispatch);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('Сохраняем временный адрес:', data);
      dispatch(setUnSavedAddress(data));
      dispatch(setShowAuthForm('login'));
    }

    toggleDrawer(false);
  };

  const fetchCities = async (locationValue) => {
    const url = `https://catalog.api.2gis.com/2.0/region/search?q=${locationValue}&key=5df490c3-58d9-4fff-953b-96e5851b97fa`;
    let response = await fetch(url);
    let commits = await response.json();
    const items = commits?.result?.items || [];
    setCitiesList(items);
    if (items.length) {
      setCityId(items[0]?.id);
    }
  };

  const fetchStreets = async (locationValue) => {
    const url = `https://catalog.api.2gis.com/3.0/suggests?q=${locationValue.trim()}&region_id=${cityId}&key=5df490c3-58d9-4fff-953b-96e5851b97fa&suggest_type=address&fields=items.address`;
    let response = await fetch(url);
    let commits = await response.json();
    setStreetsList(commits?.result?.items || []);
  };

  // Дебаунс для ввода города
  const debouncedFetchCities = useCallback(
    debounce((locationValue) => {
      fetchCities(locationValue);
    }, 300),
    []
  );

  // Дебаунс для ввода улицы
  const debouncedFetchStreets = useCallback(
    debounce((locationValue) => {
      fetchStreets(locationValue);
    }, 300),
    [cityId]
  );

  const getDefaultAddress = () => {
    if (
      showAddressForm === 'new' ||
      (!userStore && !userStore?.addresses?.length)
    )
      return null;

    return userStore?.addresses?.find((el) => el.is_default_shipping);
  };

  const isApplyDisabled = () =>
    !(
      streetsList?.length === 1 &&
      streetsList?.find((el) => el?.address?.postcode)
    );

  const sanitizeStreetValue = (value) =>
    value.replace(/[.,\/#!$%\^&\*;:{}=_`'"<>~]/g, '');

  const handleOpenDrawer = () => {
    if (!getDefaultAddress()?.address_1) dispatch(setShowAddressForm('new'));
    toggleDrawer(true);
  };

  useEffect(() => {
    const defaultAddress = getDefaultAddress();
    if (openDrawer && defaultAddress) {
      setSelectedCity(defaultAddress?.city);
      setSelectedAddress(defaultAddress?.address_1);
    }
  }, [openDrawer]);

  useEffect(() => {
    if (showAddressForm) setOpenDrawer(showAddressForm);
  }, [showAddressForm]);

  return (
    <div>
      <div className="regions-form">
        <RoundedButton className="regions-form__btn" onClick={handleOpenDrawer}>
          <PlaceOutlinedIcon />
          {getDefaultAddress()?.address_1 || 'Адрес'}
        </RoundedButton>
        <Drawer
          open={openDrawer}
          className="regions-drawer"
          onClose={() => toggleDrawer(false)}
        >
          <Box
            style={{ height: `${viewportHeight - 48}px` }}
            role="presentation"
            className="regions-drawer-container"
          >
            <div className="regions-drawer__title-box">
              <span className="main-title">Адрес доставки</span>
              <Button onClick={() => toggleDrawer(false)}>
                <CloseIcon />
              </Button>
            </div>
            <div className="regions-drawer__forms">
              <Stack spacing={4}>
                <Autocomplete
                  id="city"
                  disableClearable
                  onInputChange={(e, value) => debouncedFetchCities(value)}
                  onChange={(e, value) => setSelectedCity(value)}
                  value={selectedCity} // Привязка текущего значения
                  noOptionsText="пусто"
                  options={citiesList.map((option) => option?.name || '')}
                  renderInput={(params) => (
                    <TextField {...params} label="Город" variant="standard" />
                  )}
                />
                {console.log(streetsList)}
                <Autocomplete
                  id="street"
                  disableClearable
                  disabled={!cityId}
                  onInputChange={(e, value) =>
                    debouncedFetchStreets(sanitizeStreetValue(value))
                  }
                  onChange={(e, value) => setSelectedAddress(value.trim())}
                  value={selectedAddress.trim()} // Привязка текущего значения
                  noOptionsText="пусто"
                  options={streetsList.map(
                    (option) =>
                      sanitizeStreetValue(option?.full_name?.trim()) || ''
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Улица и дом"
                      variant="standard"
                    />
                  )}
                />
              </Stack>
            </div>
            <CommonButton
              label={getDefaultAddress() ? 'Сохранить' : 'Привезти сюда'}
              disabled={isApplyDisabled()}
              onClickHandler={onApplyFilterBtnHandler}
            />
          </Box>
        </Drawer>
      </div>
    </div>
  );
};
