// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../media/catalog-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header,
.catalog-header {
  padding: 0 10px;
}

.header-panel {
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
  align-items: center;
  gap: 20px;
  margin: 14px auto 0 auto !important;
  width: 100%;
  position: relative;
}
.header-panel .login-profile-btn {
  display: flex;
  align-items: center;
  gap: 6px;
}

.header-box {
  display: flex;
  align-items: center;
  gap: 14px;
}

.catalog-header-panel {
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
  align-items: center;
  gap: 20px;
  margin: 14px auto 0 auto !important;
  width: 100%;
}

.catalog-header-box {
  display: flex;
  align-items: center;
  gap: 14px;
}

.catalog-header {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0 60px 60px;
  height: 377px;
  flex: 0 0 auto;
}

.catalog-title {
  color: rgb(255, 255, 255);
  font-size: 8em;
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
  container-name: sidebar;
  text-transform: capitalize;
}

@container sidebar (min-width: 700px) {
  .catalog-title {
    font-size: 2em;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/HeaderPanel/index.scss"],"names":[],"mappings":"AAAA;;EAEE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;EACA,mCAAA;EACA,WAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;EACA,mCAAA;EACA,WAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAAF;;AAGA;EACE,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,4BAAA;EACA,aAAA;EACA,cAAA;AAAF;;AAGA;EACE,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,0BAAA;AAAF;;AAGA;EACE;IACE,cAAA;EAAF;AACF","sourcesContent":[".header,\n.catalog-header {\n  padding: 0 10px;\n}\n\n.header-panel {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row !important;\n  align-items: center;\n  gap: 20px;\n  margin: 14px auto 0 auto !important;\n  width: 100%;\n  position: relative;\n\n  & .login-profile-btn {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n  }\n}\n\n.header-box {\n  display: flex;\n  align-items: center;\n  gap: 14px;\n}\n\n.catalog-header-panel {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row !important;\n  align-items: center;\n  gap: 20px;\n  margin: 14px auto 0 auto !important;\n  width: 100%;\n}\n\n.catalog-header-box {\n  display: flex;\n  align-items: center;\n  gap: 14px;\n}\n\n.catalog-header {\n  background-image: url('../../media/catalog-bg.png');\n  background-repeat: no-repeat;\n  background-size: cover;\n  border-radius: 0 0 60px 60px;\n  height: 377px;\n  flex: 0 0 auto;\n}\n\n.catalog-title {\n  color: rgb(255, 255, 255);\n  font-size: 8em;\n  font-weight: 700;\n  text-align: center;\n  margin-top: 40px;\n  container-name: sidebar;\n  text-transform: capitalize;\n}\n\n@container sidebar (min-width: 700px) {\n  .catalog-title {\n    font-size: 2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
