import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { AddRounded, RemoveRounded } from '@mui/icons-material';
import { RoundedButton } from '../../shared/RoundedButton';
import React from 'react';
import {
  removeLineItemInCart,
  updateLineItemInCart,
} from '../../shared/libs/requests/cart';
import { setLocalItem } from '../../shared/libs/localStorage';
import { setCart } from '../../app/shopSlice';
import { useDispatch } from 'react-redux';

import './index.scss';

export const AddItemCalculateButton = ({
  itemQuantity,
  setItemQuantity,
  product,
  cart,
  buttonText,
}) => {
  const dispatch = useDispatch();

  const updateQuantity = async (isIncrease) => {
    try {
      let quantityTemp = itemQuantity;
      let updatedCart = {};
      if (isIncrease) quantityTemp += 1;
      if (itemQuantity > 0 && !isIncrease) quantityTemp -= 1;

      if (quantityTemp === 0) {
        const { parent } = await removeLineItemInCart(cart?.id, product?.id);
        updatedCart = parent;
      } else {
        updatedCart = await updateLineItemInCart(cart?.id, product?.id, {
          quantity: quantityTemp,
        });
      }
      setItemQuantity(quantityTemp);
      setLocalItem('cart', updatedCart);
      dispatch(setCart(updatedCart));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="add-item-calculate-btn">
      {itemQuantity > 0 ? (
        <div className="add-item-calculate-btn__quantity-controller">
          <ShoppingCartIcon />
          <RemoveRounded onClick={() => updateQuantity(false)} />
          <span className="add-item-calculate-btn__quantity">
            {itemQuantity}
          </span>
          <AddRounded onClick={() => updateQuantity(true)} />
        </div>
      ) : (
        <RoundedButton>
          <ShoppingCartIcon />
          <span>{buttonText}</span>
        </RoundedButton>
      )}
    </div>
  );
};
