import { setUser } from '../../app/userSlice';
import { setLocalItem } from './localStorage';
import { addLineItemToCart, createCart } from './requests/cart';
import { setCart } from '../../app/shopSlice';

export const saveUserInfo = (customer, dispatch) => {
  dispatch(setUser(customer));
  setLocalItem('user', customer);
};

export const formatPrice = (amount) => {
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB', // Укажите валюту региона
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount); // Преобразуем копейки в основную валюту
};

export const addToCartHandler = async (
  productData,
  cartId,
  setCartId,
  setShowSnackbar,
  setAddedProduct,
  dispatch
) => {
  setAddedProduct(productData);
  try {
    let cart = {};
    if (!cartId) {
      // Создаем новую корзину, если ее еще нет
      cart = await createCart([
        {
          variant_id: productData.variants[0].id,
          quantity: 1,
          currency_code: 'rub',
        },
      ]);
      setCartId(cart.id);
    } else {
      // Добавляем товар в существующую корзину
      cart = await addLineItemToCart(cartId, {
        variant_id: productData.variants[0].id,
        quantity: 1,
      });
    }
    setShowSnackbar(true);
    dispatch(setCart(cart));
    setLocalItem('cart', cart);
    setTimeout(() => setShowSnackbar(false), 3000);
  } catch (error) {
    console.error('Error adding product to cart:', error);
  }
};

export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
}
