import React, { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { removeLineItemInCart } from '../../shared/libs/requests/cart';
import { setCart } from '../../app/shopSlice';
import { useDispatch } from 'react-redux';
import { formatPrice } from '../../shared/libs/helpers';
import CloseIcon from '@mui/icons-material/Close';
import { setLocalItem } from '../../shared/libs/localStorage';
import { AddItemCalculateButton } from '../AddItemCalculateButton';

import './index.scss';

export const MinimalisticProductCard = ({
  product,
  xs,
  isVariant,
  onlyImage,
  cart,
  ...props
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(product?.quantity);

  const onProductNavigate = () => {
    props.onCloseSearch?.();
    navigate(`/product/${product.id}`);
  };

  const selectedVariantPrice = useMemo(() => {
    if (product?.variants?.length) {
      return product.variants.find((el) => el.calculated_price); // Берём вариант у которого есть цена как дефолтный
    }
    return null;
  }, [product]);

  const price = selectedVariantPrice?.calculated_price?.calculated_amount
    ? formatPrice(selectedVariantPrice.calculated_price.calculated_amount)
    : 'N/A';

  const variantPrice = product?.unit_price
    ? formatPrice(product?.unit_price)
    : null;

  const originalPrice = selectedVariantPrice?.calculated_price?.original_amount
    ? formatPrice(selectedVariantPrice.calculated_price.original_amount)
    : null;

  const getImgUrl = isVariant ? product?.thumbnail : product?.images?.[0]?.url;
  const getItemPrice = isVariant ? variantPrice : price;
  const getItemTitle = isVariant ? product?.product_title : product?.title;

  const removeItemHandler = async () => {
    try {
      const { parent } = await removeLineItemInCart(cart?.id, product?.id);
      dispatch(setCart(parent));
      setLocalItem('cart', parent);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid item xs={xs} spacing={4} className="minimalistic-product-card">
      <div
        className="minimalistic-product-card__image-container"
        onClick={onProductNavigate}
      >
        <span style={{ backgroundImage: `url(${getImgUrl})` }} />
      </div>
      {!onlyImage ? (
        <div className="minimalistic-product-card__info">
          <p
            className="minimalistic-product-card__title"
            onClick={onProductNavigate}
          >
            {getItemTitle}
          </p>
          <AddItemCalculateButton
            itemQuantity={quantity}
            buttonText={getItemPrice}
            setItemQuantity={setQuantity}
            product={product}
            cart={cart}
          />
        </div>
      ) : null}
      {isVariant ? (
        <CloseIcon className="close-btn" onClick={removeItemHandler} />
      ) : null}
    </Grid>
  );
};
