export const IconFulfill = () => (
  <svg
    width="19.000000"
    height="23.857422"
    viewBox="0 0 19 23.8574"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      id="Vector"
      d="M9.5 5.85L9.5 22.85"
      stroke="black"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      id="Vector"
      d="M2.21 13.14L9.5 5.85L16.78 13.14"
      stroke="black"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      id="Vector"
      d="M18 1L1 1"
      stroke="black"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
);
