import React from 'react';

import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText } from '@mui/material';

export const Paragraph01 = () => (
  <>
    <Typography variant="h4" gutterBottom fontSize="24px" fontWeight="600">
      УСЛОВИЯ ОПЛАТЫ ДЛЯ ПОКУПАТЕЛЕЙ
    </Typography>

    <Typography variant="body1" paragraph>
      При оформлении заказа в корзине вы можете оплатить банковской картой.
    </Typography>

    <Typography variant="body1" paragraph>
      Для оплаты товара с помощью банковской карты выберите необходимое
      количество товара, заполните недостающие данные и нажмите кнопку «Оплатить
      заказ».
    </Typography>

    <Typography variant="body1" paragraph>
      Оплата происходит через ООО «Банк Точка» с использованием банковских карт
      следующих платёжных систем:
    </Typography>

    <List>
      <ListItem>
        <ListItemText primary="МИР" />
      </ListItem>
      <ListItem>
        <ListItemText primary="VISA International" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Mastercard Worldwide" />
      </ListItem>
    </List>

    <Typography variant="body1" paragraph>
      Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на
      платёжный шлюз ООО «Банк Точка». Соединение с платёжным шлюзом и передача
      информации осуществляется в защищённом режиме с использованием протокола
      шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного
      проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR
      Accept, для проведения платежа также может потребоваться ввод специального
      пароля.
    </Typography>

    <Typography variant="body1" paragraph>
      Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
      сообщаемой персональной информации обеспечивается ООО «Банк Точка».
      Введённая информация не будет предоставлена третьим лицам за исключением
      случаев, предусмотренных законодательством РФ.
    </Typography>

    <Typography variant="body1" paragraph>
      Проведение платежей по банковским картам осуществляется в строгом
      соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard
      Europe Sprl.
    </Typography>

    <Typography variant="body1" paragraph>
      По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с
      работой сайта, Вы можете обращаться по телефону:
    </Typography>

    <Typography variant="h6" textAlign="center" color="primary">
      +7 993 252 64 77
    </Typography>
  </>
);
