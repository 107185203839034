// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100vh;
  max-width: unset;
}`, "",{"version":3,"sources":["webpack://./src/pages/PdfReadersPage/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;AACF","sourcesContent":[".pdf-container {\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n  width: 100%;\n  height: 100vh;\n  max-width: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
