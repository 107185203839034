import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText } from '@mui/material';

export const Paragraph03 = () => (
  <>
    <Typography variant="h4" gutterBottom fontSize="24px" fontWeight="600">
      ПРАВИЛА ОБМЕНА И ВОЗВРАТА ТОВАРА
    </Typography>

    <Typography variant="body1" paragraph>
      1. Покупатель вправе отказаться от товара, надлежащего качества
      приобретенного на сайте Продавца - www.zenhealth.ru, в любое время до его
      передачи, после передачи товара – в течение 7 (семи) дней, а в случаях,
      предусмотренных п. 4 ст. 26.1 ФЗ «О защите прав потребителей» в течение
      трех месяцев с момента передачи Товара. Возврат товара надлежащего
      качества возможен в случае, если сохранены его товарный вид,
      потребительские свойства, а также документ, подтверждающий факт и условия
      покупки указанного товара. Отсутствие у Покупателя документа,
      подтверждающего факт и условия покупки товара, не лишает его возможности
      ссылаться на другие доказательства приобретения товара у данного Продавца.
    </Typography>

    <Typography variant="body1" paragraph>
      Отказ от товара считается заявленным Покупателем:
    </Typography>

    <List>
      <ListItem>
        <ListItemText primary="- с даты отправки Продавцу скана заполненного и подписанного Покупателем заявления об отказе от товара надлежащего качества на электронную почту: www.zenhealth.ru. Форма заявления размещена на Сайте." />
      </ListItem>
      <ListItem>
        <ListItemText primary="- с даты истечения срока хранения товара на складе Транспортной компании СДЭК. Срок хранения составляет 14 (календарных) дней." />
      </ListItem>
    </List>

    <Typography variant="body1" paragraph>
      Возврат Товара надлежащего качества после передачи товара Покупателю
      осуществляется силами и за счет Покупателя путем передачи товара в
      Транспортную компанию (СДЭК) (прием отправлений выполняется на основании
      заключенного договора на оказание услуг с Продавцом) для доставки по
      следующему адресу склада СДЭК: г. Москва, ул. Сущевский вал 5 стр. 1 не
      позднее 5 (пяти) календарных дней с момента такого отказа. Товар считается
      возвращенным с даты доставки Товара Транспортной компанией по указанным в
      настоящем пункте адресам.
    </Typography>

    <Typography variant="body1" paragraph>
      Возврат товара с условием наложенного платежа не допускается. При
      нарушении данного условия Покупателем Продавец освобождается от
      обязанности по получению данной отправки и оплаты денежных средств.
    </Typography>

    <Typography variant="body1" paragraph>
      При отказе от товара надлежащего качества до его передачи возврат денежных
      средств осуществляется Продавцом в течение 10 (десяти) дней с даты
      уведомления об отказе либо с даты истечения срока хранения товара при
      условии соблюдения требований абз. 3 п. 4 ст. 26.1 Закона о защите прав
      потребителей.
    </Typography>

    <Typography variant="body1" paragraph>
      2. Последствия продажи товара ненадлежащего качества дистанционным
      способом продажи товара установлены положениями, предусмотренными статьями
      18 - 24 Закона «О защите прав потребителей». Покупатель вправе предъявить
      предусмотренные ст. 18 Закона «О защите прав потребителей» требования к
      Продавцу в отношении недостатков Товара, если они были обнаружены в
      течение гарантийного срока или срока годности Товара. Если момент
      заключения договора купли-продажи и момент передачи Товара Покупателю не
      совпадают, данные сроки исчисляются со дня доставки Товара Покупателю.
    </Typography>

    <Typography variant="body1" paragraph>
      Возврат товара ненадлежащего качества считается заявленным Покупателем с
      даты направления Продавцу скана заполненного и подписанного Покупателем
      заявления об отказе от товара по форме по адресу электронной почты
      Продавца: info@zenhealth.ru. Форма заявления размещена на Сайте.
      Покупатель прикладывает к заявлению фотографии товара (8 (восемь)
      фотографий товара с разных сторон, включая фото недостатков товара,
      сделанные в пункте выдачи СДЭК, в том числе фотографии упаковки товара
      (заводской, внешней и транспортной)).
    </Typography>

    <Typography variant="body1" paragraph>
      Продавец рассматривает указанное требование в течение 10 дней с момента
      предъявления такого требования и возврата Товара Продавцу (в случае
      предъявления им требования о возврате Товара) в порядке, предусмотренном
      Федеральным законом «О защите прав потребителей» и условиями Оферты.
    </Typography>

    <Typography variant="body1" paragraph>
      В случае видимых нарушений упаковки Покупатель вправе в момент вручения
      Товара требовать от сотрудника Транспортной компании (СДЭК) участвовать в
      досмотре содержимого и составления двустороннего акта по форме,
      установленной Транспортной компанией.
    </Typography>

    <Typography variant="body1" paragraph>
      Покупатель в случае обнаружения в товаре недостатков, если они не были
      оговорены Продавцом, по своему выбору вправе предъявить Продавцу следующие
      требования: потребовать замены на товар этой же марки (этих же модели и
      (или) артикула); потребовать замены на такой же товар другой марки
      (модели, артикула) с соответствующим перерасчетом покупной цены;
      потребовать соразмерного уменьшения покупной цены; потребовать
      незамедлительного безвозмездного устранения недостатков товара или
      возмещения расходов на их исправление потребителем или третьим лицом;
      отказаться от исполнения договора купли-продажи и потребовать возврата
      уплаченной за товар суммы; полного возмещения убытков.
    </Typography>

    <Typography variant="body1" paragraph>
      Экспертиза проводится за счет продавца (изготовителя) в сроки,
      предусмотренные ст. 20-22 Закона «О защите прав потребителей».
    </Typography>

    <Typography variant="body1" paragraph>
      Покупатель вправе присутствовать при проведении экспертизы товара и в
      случае несогласия с ее результатами оспорить заключение такой экспертизы в
      судебном порядке.
    </Typography>

    <Typography variant="body1" paragraph>
      Если в результате экспертизы товара установлено, что его недостатки
      возникли вследствие обстоятельств, за которые не отвечает Продавец,
      Покупатель обязан возместить продавцу расходы на проведение экспертизы, а
      также связанные с ее проведением расходы на хранение и транспортировку
      товара от Покупателя Продавцу и обратно.
    </Typography>

    <Typography variant="body1" paragraph>
      После подтверждения наличия в Товаре заявленных недостатков, возникших не
      по вине Покупателя, (получения соответствующего заключения экспертизы или
      удовлетворения требования Покупателя Продавцом без ее проведения) Продавец
      обязуется возместить стоимость, уплаченную Покупателем за Товар и его
      доставку, а также расходы на обратную пересылку Товара, не позднее чем
      через 10 (десяти) дней с даты вынесения решения по соответствующему
      требованию Покупателя.
    </Typography>
  </>
);
