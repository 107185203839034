import axios from 'axios';
import { getLocalItem } from '../localStorage';

const API_BASE_URL =
  process.env.REACT_APP_USE_MOCK_DATA === 'true'
    ? 'http://localhost:3001'
    : process.env.REACT_APP_MEDUSA_BACKEND_URL;

export const postRequest = async (url, body, token) => {
  const { data } = await axios.post(API_BASE_URL + url, body, {
    headers: {
      Authorization: `Bearer ${token || getLocalItem('token')}`,
      'Content-Type': 'application/json',
      'x-publishable-api-key': process.env.REACT_APP_PUBLISH_API_KEY,
      credentials: 'include',
    },
  });
  return data;
};

export const getRequest = async (url, params, token) => {
  const { data } = await axios.get(API_BASE_URL + url, {
    headers: {
      Authorization: `Bearer ${token || getLocalItem('token')}`,
      'Content-Type': 'application/json',
      'x-publishable-api-key': process.env.REACT_APP_PUBLISH_API_KEY,
      credentials: 'include',
    },
    params,
  });
  return data;
};

export const deleteRequest = async (url, token) => {
  const { data } = await axios.delete(API_BASE_URL + url, {
    headers: {
      Authorization: `Bearer ${token || getLocalItem('token')}`,
      'x-publishable-api-key': process.env.REACT_APP_PUBLISH_API_KEY,
      credentials: 'include',
    },
  });
  return data;
};
