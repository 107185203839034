import React from 'react';
import Typography from '@mui/material/Typography';
import { RoundedButton } from '../../shared/RoundedButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCustomerAddress,
  updateCustomerAddress,
} from '../../shared/libs/requests/user';
import { setShowAddressForm } from '../../app/userSlice';
import { saveUserInfo } from '../../shared/libs/helpers';

import './index.scss';

export const ProfileUserAddressSection = () => {
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user.user);

  const getActiveAddressClass = (id) =>
    userStore?.addresses?.find((el) => el.id === id)?.is_default_shipping
      ? 'active'
      : '';

  const onAddAddress = () => dispatch(setShowAddressForm('new'));

  const onChangeAddress = async (id) => {
    const updatedCustomer = await updateCustomerAddress(id, {
      is_default_shipping: true,
      is_default_billing: true,
    });
    saveUserInfo(updatedCustomer, dispatch);
  };

  const onRemoveAddress = async (id) => {
    try {
      const removedAddress = await deleteCustomerAddress(id);
      const filteredAddresses = userStore.addresses.length
        ? userStore.addresses.filter((el) => el.id !== id)
        : [];
      const userTemp = { ...userStore, addresses: filteredAddresses };
      saveUserInfo(userTemp, dispatch);
      console.log('Адрес удален', removedAddress);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="profile-user-address-container">
      <div className="address-block">
        {userStore?.addresses?.length ? (
          userStore.addresses
            .map((el) => (
              <div
                className={`address-container ${getActiveAddressClass(el.id)}`}
              >
                <Typography className="address-name">{el.address_1}</Typography>
                <div className="address-action-container">
                  <RoundedButton
                    className="address-action-btn"
                    onClick={() => onChangeAddress(el.id)}
                  >
                    Выбрать
                  </RoundedButton>
                  <RoundedButton
                    className="address-action-btn"
                    onClick={() => onRemoveAddress(el.id)}
                  >
                    Удалить
                  </RoundedButton>
                </div>
              </div>
            ))
            .reverse()
        ) : (
          <Typography className="empty-address-list">
            У вас пока нет адресов
          </Typography>
        )}
      </div>
      <RoundedButton onClick={onAddAddress}>+ Добавить адрес</RoundedButton>
    </div>
  );
};
