export const i18n = {
  validation: {
    email: {
      invalid: 'Неправильный формат электронной почты',
      required: 'Требуется ввести электронную почту',
    },
    phone: {
      invalid: 'Неправильный формат номера телефона',
      required: 'Требуется ввести номер телефона',
    },
    password: {
      minLength: 'Пароль должен содержать минимум 8 символов',
      required: 'Требуется ввести пароль',
    },
  },
};
