import { deleteRequest, getRequest, postRequest } from './index';

export const createCart = async (items = []) => {
  const cartBody = {
    items,
  };

  const { cart } = await postRequest('/store/carts', cartBody);
  return cart;
};

export const updateCart = async (cartId, params) => {
  const { cart } = await postRequest(`/store/carts/${cartId}`, params);
  return cart;
};

export const completeCart = async (cartId, params) => {
  const { cart } = await postRequest(`/store/carts/${cartId}/complete`, params);
  return cart;
};

export const addLineItemToCart = async (cartId, updates) => {
  const { cart } = await postRequest(
    `/store/carts/${cartId}/line-items`,
    updates
  );
  return cart;
};

export const updateLineItemInCart = async (cartId, itemId, updates) => {
  const { cart } = await postRequest(
    `/store/carts/${cartId}/line-items/${itemId}`,
    updates
  );
  return cart;
};

export const removeLineItemInCart = async (cartId, itemId) =>
  await deleteRequest(`/store/carts/${cartId}/line-items/${itemId}`);

export const updateShippingMethod = async (cartId, method) => {
  const { shipping_options } = await getRequest(
    `/store/shipping-options?cart_id=${cartId}`
  );

  const cart = await postRequest(`/store/carts/${cartId}/shipping-methods`, {
    option_id: shipping_options?.find((el) => el.name === method)?.id,
    data: {
      name: method,
    },
  });

  return cart;
};
