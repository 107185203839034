// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-card-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 600px;
}

.catalog-subcategory {
  position: absolute;
  top: 0;
  left: 300px;
  width: 300px;
  padding: 6px 16px;
}
.catalog-subcategory .MuiListItemText-root {
  margin: 2.5px 0 !important;
}
.catalog-subcategory span {
  font-size: 14px !important;
  color: rgb(128, 128, 128) !important;
  font-weight: 500 !important;
  line-height: 12px;
  text-align: left;
}
.catalog-subcategory span.hovered {
  color: black;
}

.catalog-main-category {
  width: 300px;
  color: rgb(128, 128, 128);
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  padding: 10px 0;
}
.catalog-main-category .MuiListItemButton-root {
  padding: 8px 16px;
}
.catalog-main-category:hover {
  color: black !important;
  cursor: pointer;
}
.catalog-main-category:hover:after {
  border: 6px solid;
  border-color: transparent transparent transparent black;
}`, "",{"version":3,"sources":["webpack://./src/features/HeaderCatalogForm/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AACF;AACE;EACE,0BAAA;AACJ;AAEE;EACE,0BAAA;EACA,oCAAA;EACA,2BAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,YAAA;AAAN;;AAKA;EACE,YAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AAFF;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,uBAAA;EACA,eAAA;AAHJ;AAKI;EACE,iBAAA;EACA,uDAAA;AAHN","sourcesContent":[".catalog-card-container {\n  display: flex;\n  position: absolute;\n  top: 0;\n  left: 600px;\n}\n\n.catalog-subcategory {\n  position: absolute;\n  top: 0;\n  left: 300px;\n  width: 300px;\n  padding: 6px 16px;\n\n  .MuiListItemText-root {\n    margin: 2.5px 0 !important;\n  }\n\n  & span {\n    font-size: 14px !important;\n    color: rgb(128, 128, 128) !important;\n    font-weight: 500 !important;\n    line-height: 12px;\n    text-align: left;\n\n    &.hovered {\n      color: black;\n    }\n  }\n}\n\n.catalog-main-category {\n  width: 300px;\n  color: rgb(128, 128, 128);\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 12px;\n  text-align: left;\n  padding: 10px 0;\n\n  .MuiListItemButton-root {\n    padding: 8px 16px;\n  }\n\n  &:hover {\n    color: black !important;\n    cursor: pointer;\n\n    &:after {\n      border: 6px solid;\n      border-color: transparent transparent transparent black;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
