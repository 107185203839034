// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-page {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: auto;
  text-align: center;
  align-items: center;
  height: 100vh;
  justify-content: center;
  gap: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,SAAA;AACF","sourcesContent":[".not-found-page {\n  display: flex;\n  flex-direction: column;\n  max-width: 1440px;\n  margin: auto;\n  text-align: center;\n  align-items: center;\n  height: 100vh;\n  justify-content: center;\n  gap: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
