import React from 'react';
import { HeaderPanel } from '../../widgets/HeaderPanel';
import { FooterPanel } from '../../widgets/FooterPanel';
import DiscountModalForm from '../../features/DiscountModalForm';
import { DiscountHeaderCapForm } from '../../features/DiscountHeaderCapForm';

import './index.scss';

export const DefaultPageContainer = ({ children, title = null }) => (
  <div className="main-page">
    <DiscountHeaderCapForm />
    <DiscountModalForm />
    <HeaderPanel title={title} />
    {children}
    <FooterPanel />
  </div>
);
