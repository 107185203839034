// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.regions-form__btn {
  font-weight: 600 !important;
  text-transform: unset !important;
  display: flex;
  align-items: center;
  gap: 6px;
}

.regions-drawer__forms {
  flex: 1 1 auto;
}
.regions-drawer__title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.regions-drawer__title-box .main-title {
  font-weight: 500;
  font-size: 1.5rem;
}

.regions-drawer-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 24px;
  width: 480px;
}
.regions-drawer-container .common-button {
  flex-shrink: 0;
}

.MuiPaper-root.MuiPaper-elevation::-webkit-scrollbar {
  padding: 10px;
  width: 12px;
}
.MuiPaper-root.MuiPaper-elevation::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  height: 100px;
}
.MuiPaper-root.MuiPaper-elevation::-webkit-scrollbar-thumb {
  border: 4.1px solid #fcfcfc;
  border-radius: 13px;
  background-color: #c4c4c4;
}`, "",{"version":3,"sources":["webpack://./src/features/HeaderRegionsForm/index.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AAAJ;;AAKE;EACE,cAAA;AAFJ;AAIE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAFJ;AAII;EACE,gBAAA;EACA,iBAAA;AAFN;;AAOA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,YAAA;AAJF;AAME;EACE,cAAA;AAJJ;;AASE;EACE,aAAA;EACA,WAAA;AANJ;AAQE;EACE,0CAAA;EACA,aAAA;AANJ;AAQE;EACE,2BAAA;EACA,mBAAA;EACA,yBAAA;AANJ","sourcesContent":[".regions-form {\n  &__btn {\n    font-weight: 600 !important;\n    text-transform: unset !important;\n    display: flex;\n    align-items: center;\n    gap: 6px;\n  }\n}\n\n.regions-drawer {\n  &__forms {\n    flex: 1 1 auto;\n  }\n  &__title-box {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 20px;\n\n    & .main-title {\n      font-weight: 500;\n      font-size: 1.5rem;\n    }\n  }\n}\n\n.regions-drawer-container {\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n  padding: 24px;\n  width: 480px;\n\n  & .common-button {\n    flex-shrink: 0;\n  }\n}\n\n.MuiPaper-root.MuiPaper-elevation {\n  &::-webkit-scrollbar {\n    padding: 10px;\n    width: 12px;\n  }\n  &::-webkit-scrollbar-track {\n    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);\n    height: 100px;\n  }\n  &::-webkit-scrollbar-thumb {\n    border: 4.1px solid #fcfcfc;\n    border-radius: 13px;\n    background-color: #c4c4c4;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
