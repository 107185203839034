import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RoundedButton } from '../../shared/RoundedButton';
import { useNavigate } from 'react-router-dom';

import './index.scss';

export const ThankYouModalForm = ({
  showSuccessModal,
  setShowSuccessModal,
}) => {
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => setShowSuccessModal(newOpen);
  const onOrderListNavigate = () => navigate(`/profile/2`);

  return (
    <Drawer
      open={showSuccessModal}
      anchor="right"
      className="success-drawer"
      onClose={toggleDrawer('')}
    >
      <Box
        role="presentation"
        className="success-drawer-container"
        sx={{ width: '700px', gap: 'unset' }}
      >
        <Typography className="title green-border" variant="h5" gutterBottom>
          Заказ успешно оформлен.
        </Typography>
        <Typography className="description">
          Спасибо, что выбираете нас! Ваш заказ сформирован и мы уже его
          собираем. Всю информацию по заказу Вы можете увидеть в личном
          кабинете!
        </Typography>
        <Box>
          <RoundedButton
            color="primary"
            variant="contained"
            className="login-form__submit-btn"
            fullWidth
            type="submit"
            sx={{ mt: 2 }}
            onClick={onOrderListNavigate}
          >
            Личный кабинет
          </RoundedButton>
        </Box>
      </Box>
    </Drawer>
  );
};
