import React, { useState } from 'react';

import { HeaderSearchField } from '../../features/HeaderSearchField';
import { HeaderLogoIcon } from '../../features/HeaderLogoIcon';
import { RoundedButton } from '../../shared/RoundedButton';
import { HeaderCatalogForm } from '../../features/HeaderCatalogForm';
import { HeaderRegionsForm } from '../../features/HeaderRegionsForm';
import { HeaderFavoriteForm } from '../../features/HeaderFavoriteForm';
import { useNavigate } from 'react-router-dom';
import { AuthPanelForm } from '../../features/AuthPanelForm';
import { LoginForm } from '../../features/LoginPanelForm';
import { CartModalForm } from '../../features/CartModalForm';

import { Badge } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AccountCircleOutlined } from '@mui/icons-material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { setShowAuthForm } from '../../app/userSlice';

import './index.scss';

export const HeaderPanel = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showCartPanel, setShowCartPanel] = useState(false);
  const userStore = useSelector((state) => state.user.user);
  const cartStore = useSelector((state) => state.shop.cart);

  const profileBtnHandler = () => {
    if (userStore?.id) {
      navigate(`/profile`);
      return;
    }
    dispatch(setShowAuthForm('login'));
  };

  const openCartPanel = () => {
    setShowCartPanel(true);
  };

  return (
    <div className={title ? 'catalog-header' : 'header'}>
      <div
        className={`main-container header-panel ${title ? 'catalog-header-panel' : null}`}
      >
        <HeaderLogoIcon />
        <HeaderCatalogForm />
        <HeaderSearchField />
        <HeaderRegionsForm />
        <HeaderFavoriteForm />
        <div className="header-box">
          {cartStore?.items ? (
            <Badge badgeContent={cartStore?.items?.length} color="primary">
              <RoundedButton onClick={openCartPanel}>
                <ShoppingCartOutlinedIcon />
              </RoundedButton>
            </Badge>
          ) : (
            <RoundedButton onClick={openCartPanel}>
              <ShoppingCartOutlinedIcon />
            </RoundedButton>
          )}

          <RoundedButton onClick={profileBtnHandler}>
            {userStore?.id ? (
              <span className="login-profile-btn">
                <AccountCircleOutlined fontSize="large" />
                Профиль
              </span>
            ) : (
              'Войти'
            )}
          </RoundedButton>
          <AuthPanelForm />
          <LoginForm />
          <CartModalForm
            showCartPanel={showCartPanel}
            setShowCartPanel={setShowCartPanel}
          />
        </div>
      </div>
      {title ? <h1 className="catalog-title">{title}</h1> : null}
    </div>
  );
};
