import { getRequest, postRequest } from './index';

export const retrievePaymentProviders = async (cart) => {
  const { payment_providers } = await getRequest(
    `/store/payment-providers?region_id=${cart?.region_id}`
  );
  return payment_providers;
};

export const selectPaymentProvider = async (paymentProviderId, cart, user) => {
  let paymentCollectionId = cart.payment_collection?.id;

  if (!paymentCollectionId) {
    // create payment collection
    const { payment_collection } = await postRequest(
      `/store/payment-collections`,
      { cart_id: cart.id }
    );
    paymentCollectionId = payment_collection.id;
  }

  // initialize payment session
  const paymentData = await postRequest(
    `/store/payment-collections/${paymentCollectionId}/payment-sessions`,
    {
      provider_id: paymentProviderId,
      context: {
        extra: {
          email: user?.email,
          first_name: user?.first_name,
          last_name: user?.last_name,
          phone: user?.phone,
        },
      },
    }
  );
  const { cart: updatedCart } = await getRequest(`/store/carts/${cart.id}`);
  return { updatedCart, payment: paymentData };
};

export const getPaymentUi = (cart) => {
  const activePaymentSession = cart?.payment_collection?.payment_sessions?.[0];

  if (!activePaymentSession) {
    return;
  }

  switch (true) {
    case activePaymentSession.provider_id.startsWith(
      'pp_Точка_tochka-payments'
    ):
      // TODO handle Stripe UI
      return 'You chose stripe!';
    case activePaymentSession.provider_id.startsWith('pp_system_default'):
      return 'You chose manual payment! No additional actions required.';
    default:
      return `You chose ${activePaymentSession.provider_id} which is in development.`;
  }
};
