// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-user-faq-container {
  display: flex;
  margin-top: 64px;
  margin-bottom: 164px;
  gap: 20px;
}
.profile-user-faq-container .user-faq-navbar-container {
  width: 320px;
  max-width: 320px;
  flex: 1 0 auto;
  position: sticky;
  top: 0;
  height: 100%;
  padding: 26px 30px;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.profile-user-faq-container .user-faq-navbar-container > p {
  font-weight: 600;
  color: rgb(0, 0, 0);
  font-size: 14px;
  text-transform: uppercase;
}
.profile-user-faq-container .user-faq-navbar-container > p:hover {
  cursor: pointer;
}
.profile-user-faq-container .user-faq-content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 25px;
  padding: 26px 30px;
  background: rgb(255, 255, 255);
}
.profile-user-faq-container .user-faq-content-container span {
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/features/ProfileUserFAQSection/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,oBAAA;EACA,SAAA;AACF;AACE;EACE,YAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,MAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,+BAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;AACN;AACM;EACE,eAAA;AACR;AAIE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,sBAAA;EACA,oCAAA;EACA,mBAAA;EACA,kBAAA;EAEA,8BAAA;AAHJ;AAKI;EACE,gBAAA;AAHN","sourcesContent":[".profile-user-faq-container {\n  display: flex;\n  margin-top: 64px;\n  margin-bottom: 164px;\n  gap: 20px;\n\n  & .user-faq-navbar-container {\n    width: 320px;\n    max-width: 320px;\n    flex: 1 0 auto;\n    position: sticky;\n    top: 0;\n    height: 100%;\n    padding: 26px 30px;\n    border-radius: 25px;\n    background: rgba(0, 0, 0, 0.03);\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n\n    & > p {\n      font-weight: 600;\n      color: rgb(0, 0, 0);\n      font-size: 14px;\n      text-transform: uppercase;\n\n      &:hover {\n        cursor: pointer;\n      }\n    }\n  }\n\n  & .user-faq-content-container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    box-sizing: border-box;\n    border: 1px solid rgb(204, 204, 204);\n    border-radius: 25px;\n    padding: 26px 30px;\n\n    background: rgb(255, 255, 255);\n\n    & span {\n      font-weight: 500;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
