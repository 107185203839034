import React, { useState } from 'react';

import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import InputMask from 'react-input-mask';
import { RoundedButton } from '../../shared/RoundedButton';
import { PromptModalWindow } from '../../widgets/PromptModalWindow';
import { setUser } from '../../app/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLocalItem, removeLocalItem } from '../../shared/libs/localStorage';
import { updateCustomer } from '../../shared/libs/requests/user';
import { saveUserInfo } from '../../shared/libs/helpers';
import { tempRetrieveAuthToken } from '../../shared/libs/requests/auth';

import './index.scss';
import { deleteRequest } from '../../shared/libs/requests';

export const ProfileUserInfoSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.user.user);

  const [formData, setFormData] = useState({
    name: userStore?.first_name || '',
    surname: userStore?.last_name || '',
    male: userStore?.metadata?.gender || 'male',
    birthday: userStore?.metadata?.birthday || null,
    phone: userStore?.phone || '',
    email: userStore?.email || userStore?.metadata?.email || '',
  });

  const [errors, setErrors] = useState({});
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Необходимо ввести ваше имя';
    }

    if (
      formData.phone.trim() &&
      !/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(formData.phone)
    ) {
      newErrors.phone = 'Неверный формат телефона';
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Неверный формат почты';
    }

    // Проверка на корректную дату рождения
    if (!formData.birthday || isNaN(new Date(formData.birthday))) {
      newErrors.birthday = 'Некорректная дата рождения';
    } else {
      const birthdayDate = new Date(formData.birthday);
      const today = new Date();
      const minDate = new Date(
        today.getFullYear() - 120,
        today.getMonth(),
        today.getDate()
      );

      if (birthdayDate > today) {
        newErrors.birthday = 'Дата рождения не может быть в будущем';
      } else if (birthdayDate < minDate) {
        newErrors.birthday = 'Дата рождения не может быть старше 120 лет';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async () => {
    try {
      if (validateForm()) {
        const customer = await updateCustomer({
          first_name: formData.name,
          last_name: formData.surname,
          phone: formData.phone,
          metadata: {
            gender: formData.male,
            birthday: formData.birthday,
            email: formData.email,
          },
        });
        saveUserInfo(customer, dispatch);
      }
    } catch (error) {
      console.error('Failed to save user information', error);
    }
  };

  const handleChange = (field) => (event) => {
    setFormData((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const logoutModalHandler = async () => {
    try {
      const token = await tempRetrieveAuthToken();
      //TODO: fix problem with auth, deleter request return 401
      await deleteRequest(`/auth/session`, token);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setUser(null));
      navigate('/');
      removeLocalItem('token');
      removeLocalItem('user');
      removeLocalItem('clientData');
      removeLocalItem('cart');
      removeLocalItem('updatedCart');
    }
  };

  return (
    <div className="profile-user-info-container">
      <h3 className="tab-title">Личная информация</h3>
      <TextField
        id="field-name"
        label="Имя"
        variant="standard"
        value={formData.name}
        onChange={handleChange('name')}
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField
        id="field-surname"
        label="Фамилия"
        variant="standard"
        value={formData.surname}
        onChange={handleChange('surname')}
        error={!!errors.surname}
        helperText={errors.surname}
      />

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="male"
        name="radio-buttons-group"
        value={formData.male}
        onChange={handleChange('male')}
      >
        <FormControlLabel value="male" control={<Radio />} label="Мужчина" />
        <FormControlLabel value="female" control={<Radio />} label="Женщина" />
      </RadioGroup>
      {errors.male && <div className="error-text">{errors.male}</div>}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField
          label="Дата рождения"
          disableFuture
          variant="standard"
          value={dayjs(formData.birthday)}
          onChange={(newValue) =>
            setFormData((prev) => ({ ...prev, birthday: newValue }))
          }
          error={!!errors.birthday}
          helperText={errors.birthday}
        />
      </LocalizationProvider>

      <InputMask
        mask="+7 (999) 999-99-99"
        value={formData.phone}
        onChange={handleChange('phone')}
      >
        {(inputProps) => (
          <TextField
            {...inputProps}
            label="Телефон"
            variant="standard"
            error={!!errors.phone}
            helperText={errors.phone}
          />
        )}
      </InputMask>
      <TextField
        id="field-mail"
        label="Почта"
        type="email"
        required
        variant="standard"
        value={formData.email}
        onChange={handleChange('email')}
        error={!!errors.email}
        helperText={errors.email}
      />
      <div className="user-info__action-container">
        <RoundedButton
          onClick={onSubmitHandler}
          className="user-info__save-btn"
        >
          Сохранить
        </RoundedButton>
        <RoundedButton
          className="user-info__exit-btn"
          onClick={() => setShowLogoutModal(true)}
        >
          Выйти из аккаунта
        </RoundedButton>
        <PromptModalWindow
          open={showLogoutModal}
          handleClose={() => setShowLogoutModal(false)}
          handleLogout={logoutModalHandler}
        />
      </div>
    </div>
  );
};
