import { setShowAuthForm } from '../../../app/userSlice';

export const openAuthFormForUnauthorizedActions = (
  userId,
  action,
  dispatch
) => {
  if (userId) return action();

  dispatch(setShowAuthForm('login'));
};
