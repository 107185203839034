// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-user-favorite-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  margin-bottom: 164px;
}
.profile-user-favorite-container .empty-favorite-list {
  font-weight: 500;
  margin-top: 50px;
}
.profile-user-favorite-container .tab-title {
  font-size: 30px;
  margin: 40px 0 10px 0;
  text-transform: uppercase;
}
.profile-user-favorite-container .MuiFormGroup-root.MuiRadioGroup-root {
  display: flex;
  flex-direction: row;
}
.profile-user-favorite-container .user-info__action-container {
  display: flex;
  gap: 20px;
}
.profile-user-favorite-container .user-info__action-container .user-info__save-btn {
  background: black;
  padding: 20px 30px;
  color: white !important;
}
.profile-user-favorite-container .user-info__action-container .user-info__exit-btn {
  padding: 20px 30px;
}
.profile-user-favorite-container .MuiGrid-item.catalog-product-card {
  max-width: 380px !important;
}`, "",{"version":3,"sources":["webpack://./src/features/ProfileUserFavoriteection/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;EACA,oBAAA;AACF;AACE;EACE,gBAAA;EACA,gBAAA;AACJ;AAEE;EACE,eAAA;EACA,qBAAA;EACA,yBAAA;AAAJ;AAOE;EACE,aAAA;EACA,mBAAA;AALJ;AAQE;EACE,aAAA;EACA,SAAA;AANJ;AAQI;EACE,iBAAA;EACA,kBAAA;EACA,uBAAA;AANN;AASI;EACE,kBAAA;AAPN;AAWE;EACE,2BAAA;AATJ","sourcesContent":[".profile-user-favorite-container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  max-width: 100%;\n  margin-bottom: 164px;\n\n  & .empty-favorite-list {\n    font-weight: 500;\n    margin-top: 50px;\n  }\n\n  & .tab-title {\n    font-size: 30px;\n    margin: 40px 0 10px 0;\n    text-transform: uppercase;\n  }\n\n  //& .MuiButton-root {\n  //  margin-top: 40px;\n  //}\n\n  & .MuiFormGroup-root.MuiRadioGroup-root {\n    display: flex;\n    flex-direction: row;\n  }\n\n  & .user-info__action-container {\n    display: flex;\n    gap: 20px;\n\n    & .user-info__save-btn {\n      background: black;\n      padding: 20px 30px;\n      color: white !important;\n    }\n\n    & .user-info__exit-btn {\n      padding: 20px 30px;\n    }\n  }\n\n  .MuiGrid-item.catalog-product-card {\n    max-width: 380px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
