import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { CatalogProductCard } from '../CatalogProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredProduct } from '../../shared/libs/requests/product';
import { CardSkeletonForm } from '../CardSkeletonForm';
import Typography from '@mui/material/Typography';
import { addToCartHandler } from '../../shared/libs/helpers';
import { AddItemToCartPopup } from '../AddItemToCartPopup';

import './index.scss';

export const ProfileUserFavoriteSection = () => {
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState({});
  const [addedProduct, setAddedProduct] = useState(null);
  const [cartId, setCartId] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const getProducts = async (favList) => {
    try {
      if (favList?.length)
        return await getFilteredProduct({
          id: userStore.metadata.favoriteItems ?? [],
        });
      else return { count: 0, products: [] };
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (userStore?.metadata?.favoriteItems) {
      getProducts(userStore?.metadata?.favoriteItems).then((res) =>
        setProducts(res)
      );
    }
    setLoading(false);
  }, []);

  return (
    <div className="profile-user-favorite-container">
      <AddItemToCartPopup
        triggerSnackbar={showSnackbar}
        product={addedProduct}
      />
      {loading || !products?.products ? (
        <CardSkeletonForm cardsCount={8} xsCount={4} />
      ) : products?.products?.length > 0 ? (
        <Grid container spacing={4}>
          {products?.products?.map((product) => (
            <CatalogProductCard
              key={product.id}
              product={product}
              addToCartHandler={(product) =>
                addToCartHandler(
                  product,
                  cartId,
                  setCartId,
                  setShowSnackbar,
                  setAddedProduct,
                  dispatch
                )
              }
              xs={4}
            />
          ))}
        </Grid>
      ) : (
        <Typography className="empty-favorite-list">
          У вас пока нет товаров в разделе Избранное
        </Typography>
      )}
    </div>
  );
};
