// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-form {
  margin: 40px auto 30px auto !important;
}
.catalog-form .padding-container {
  padding: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/CatalogForm/index.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":[".catalog-form {\n  margin: 40px auto 30px auto !important;\n\n  .padding-container {\n    padding: 0 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
