import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import './index.scss';

export const CommonButton = ({ label, onClickHandler, disabled }) => {
  return (
    <Button
      component="label"
      variant="text"
      className="common-button"
      onClick={onClickHandler}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

CommonButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
};
