import React from 'react';

export const IconMobileLogo = () => (
  <svg
    width="30.000000"
    height="31.000000"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      id="Vector"
      d="M30 25.18C30 28.39 23.38 31 15.21 31C7.05 31 0 28.39 0 25.18C0 21.97 7.05 19.37 15.21 19.37C23.38 19.37 30 21.97 30 25.18ZM14.83 9.04C8.36 9.04 3.12 11.06 3.12 13.56C3.12 16.05 8.36 18.08 14.83 18.08C21.29 18.08 27.09 16.05 27.09 13.56C27.09 11.06 21.29 9.04 14.83 9.04ZM13.37 0C9.62 0 6.15 1.73 6.15 3.87C6.15 6.01 9.62 7.75 13.37 7.75C17.11 7.75 20.14 6.01 20.14 3.87C20.14 1.73 17.11 0 13.37 0Z"
      fill="#000000"
      fillOpacity="1.000000"
      fillRule="nonzero"
    />
  </svg>
);
