import React from 'react';

import Typography from '@mui/material/Typography';

export const Paragraph02 = () => (
  <>
    <Typography variant="h4" gutterBottom fontSize="24px" fontWeight="600">
      УСЛОВИЯ ДОСТАВКИ
    </Typography>

    <Typography variant="body1" paragraph>
      Доставка Товара (груза) осуществляется транспортной компанией СДЭК.
    </Typography>

    <Typography variant="body1" paragraph>
      Рассчитать стоимость доставки можно на сайте при оформлении товара.
      Отправка Товара осуществляется из Московской области.
    </Typography>

    <Typography variant="body1" paragraph>
      Осуществление отправки производится после поступления денежных средств в
      полном объеме от Покупателя на расчетный счет Продавца.
    </Typography>

    <Typography variant="body1" paragraph>
      После оплаты Покупатель получает подтверждение заказа по электронной
      почте, в чат мессенджера по указанному Покупателем номеру телефона либо
      иным согласованным с Покупателем способом, содержащее идентификационные
      сведения об оплаченном Заказе.
    </Typography>

    <Typography variant="body1" paragraph>
      Отслеживание груза Покупатель осуществляет самостоятельно в личном
      кабинете Покупателя на сайте Интернет-магазина Продавца или в личном
      кабинете СДЭК. Регистрация в личном кабинете СДЭК и отслеживание груза
      осуществляется по номеру телефона, используемого для оформления заказа.
    </Typography>

    <Typography variant="body1" paragraph>
      В случае если у Покупателя нет зарегистрированного личного кабинета СДЭК,
      возможно получение Покупателем сообщения о доставке Товара в мессенджерах
      «ВКонтакте» или Viber.
    </Typography>

    <Typography variant="body1" paragraph>
      Оплаченный товар комплектуется и передаётся в транспортную компанию в
      течение 3 (трех) рабочих дней с даты зачисления оплаты на расчетный счет
      Продавца. Конечные сроки получения Товара Покупателем зависят от адреса и
      региона доставки, работы конкретной транспортной компании.
    </Typography>

    <Typography variant="body1" paragraph>
      Доставка Заказа осуществляется только в здания и помещения (офис,
      квартира, проч.) по системе «от двери до двери» либо «склад – склад» (по
      выбору Покупателя). В общественных местах, аэропортах, на остановках,
      вокзалах и т.п. передача Заказов не производится.
    </Typography>

    <Typography variant="body1" paragraph>
      После оформления и подтверждения отправки груза, мы отправляем Вам
      идентификационные сведения, для отслеживания груза.
    </Typography>

    <Typography variant="body1" paragraph>
      Стоимость доставки Товара рассчитывается индивидуально, исходя из его
      веса, объема, способа и региона доставки.
    </Typography>

    <Typography variant="body1" paragraph>
      В случае, если товар не был Вами получен в сроки, указанные транспортной
      компанией, по не зависящим от транспортной компании обстоятельствам,
      повторная доставка оплачивается Вами отдельно. Обрешетка или
      паллетирование груза осуществляется по запросу Покупателя за его счет. За
      деформированный груз, полученный Покупателем в транспортной компании без
      составления соответствующего двустороннего акта Продавец ответственности
      не несет!
    </Typography>
  </>
);
