import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import PropTypes from 'prop-types';

import './index.scss';

export const CatalogCategoryFilter = ({ categoryValues, onDetailsChange }) => {
  return (
    <div className="category-filter">
      {categoryValues?.map((category) =>
        category?.list?.length ? (
          <Accordion key={category.id}>
            <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
              <Typography>{category.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {category.list.map((el) => (
                  <FormControlLabel
                    key={el.type}
                    control={
                      <Checkbox
                        checked={el.isActive}
                        onChange={() => onDetailsChange(category.id, el.type)}
                      />
                    }
                    label={el.type}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        ) : null
      )}
    </div>
  );
};

CatalogCategoryFilter.propTypes = {
  categoryValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      list: PropTypes.array.isRequired,
    }).isRequired
  ).isRequired,
  onDetailsChange: PropTypes.func.isRequired,
};
