// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-item-calculate-btn label {
  height: 34px;
}
.add-item-calculate-btn svg {
  margin-right: 10px;
  width: 20px !important;
  height: 20px !important;
}
.add-item-calculate-btn span {
  font-size: 18px;
  font-weight: 600;
  line-height: 111%;
  text-align: left;
  font-family: Montserrat;
}
.add-item-calculate-btn button {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
}
.add-item-calculate-btn__quantity-controller {
  display: flex;
  align-items: center;
  background: black;
  border-radius: 20px;
  padding: 6px 16px;
  width: max-content;
  gap: 6px;
}
.add-item-calculate-btn__quantity-controller svg {
  color: white;
  fill: white;
  margin-right: 0 !important;
}
.add-item-calculate-btn__quantity-controller svg:hover {
  cursor: pointer;
}
.add-item-calculate-btn__quantity-controller span {
  color: rgb(0, 237, 80);
  min-width: 20px;
  text-align: center !important;
}`, "",{"version":3,"sources":["webpack://./src/features/AddItemCalculateButton/index.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AAEE;EACE,kBAAA;EACA,sBAAA;EACA,uBAAA;AAAJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,QAAA;AADJ;AAGI;EACE,YAAA;EACA,WAAA;EACA,0BAAA;AADN;AAII;EACE,eAAA;AAFN;AAKI;EACE,sBAAA;EACA,eAAA;EACA,6BAAA;AAHN","sourcesContent":[".add-item-calculate-btn {\n  & label {\n    height: 34px;\n  }\n  & svg {\n    margin-right: 10px;\n    width: 20px !important;\n    height: 20px !important;\n  }\n  & span {\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 111%;\n    text-align: left;\n    font-family: Montserrat;\n  }\n  button {\n    display: flex;\n    align-items: center;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 30px;\n  }\n\n  &__quantity-controller {\n    display: flex;\n    align-items: center;\n    background: black;\n    border-radius: 20px;\n    padding: 6px 16px;\n    width: max-content;\n    gap: 6px;\n\n    & svg {\n      color: white;\n      fill: white;\n      margin-right: 0 !important;\n    }\n\n    & svg:hover {\n      cursor: pointer;\n    }\n\n    & span {\n      color: rgb(0, 237, 80);\n      min-width: 20px;\n      text-align: center !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
