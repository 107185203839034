import React from 'react';
import { Grid } from '@mui/material';
import { CardSkeleton } from '../../shared/CardSkeleton';

import './index.scss';

export const CardSkeletonForm = ({
  cardsCount,
  isMinimalistic = false,
  xsCount = 3,
  isVertical = false,
}) => (
  <Grid
    className={`${isVertical ? 'vertical' : 'horizontal'}`}
    container
    spacing={2}
    margin="20px 0"
  >
    {[...Array(cardsCount)].map((_, index) => (
      <CardSkeleton key={index} isMinimalistic={isMinimalistic} xs={xsCount} />
    ))}
  </Grid>
);
