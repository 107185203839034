export const IconSuccess = () => (
  <svg
    width="27.007812"
    height="19.191406"
    viewBox="0 0 27.0078 19.1914"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      id="Vector"
      d="M26 1L8.81 18.19L1 10.37"
      stroke="black"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
);
