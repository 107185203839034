import React from 'react';
import { IconLogo } from '../../shared/IconLogo';
import { NavLink } from 'react-router-dom';
import { IconMobileLogo } from '../../shared/IconMobileLogo';

import './index.scss';

export const HeaderLogoIcon = () => {
  const viewportWidth = window.innerWidth;
  return (
    <NavLink to="/">
      <div className="logo">
        {viewportWidth >= 480 ? <IconLogo /> : <IconMobileLogo />}
      </div>
    </NavLink>
  );
};
