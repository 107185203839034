export const IconShipping = () => (
  <svg
    width="27.000000"
    height="22.000000"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      id="Vector"
      d="M16 18.5L16 3.5C16 2.83 15.73 2.2 15.26 1.73C14.79 1.26 14.16 1 13.5 1L3.5 1C2.83 1 2.2 1.26 1.73 1.73C1.26 2.2 1 2.83 1 3.5L1 17.25C1 17.58 1.13 17.89 1.36 18.13C1.6 18.36 1.91 18.5 2.25 18.5L4.75 18.5"
      stroke="#000000"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      id="Vector"
      d="M17.25 18.5L9.75 18.5"
      stroke="#000000"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      id="Vector"
      d="M22.25 18.5L24.75 18.5C25.08 18.5 25.39 18.36 25.63 18.13C25.86 17.89 26 17.58 26 17.25L26 12.68C25.99 12.4 25.9 12.12 25.72 11.9L21.37 6.47C21.25 6.32 21.1 6.2 20.94 6.12C20.77 6.04 20.58 6 20.4 6L16 6"
      stroke="#000000"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      id="Vector"
      d="M19.75 21C18.36 21 17.25 19.88 17.25 18.5C17.25 17.11 18.36 16 19.75 16C21.13 16 22.25 17.11 22.25 18.5C22.25 19.88 21.13 21 19.75 21Z"
      stroke="#000000"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
    />
    <path
      id="Vector"
      d="M7.25 21C5.86 21 4.75 19.88 4.75 18.5C4.75 17.11 5.86 16 7.25 16C8.63 16 9.75 17.11 9.75 18.5C9.75 19.88 8.63 21 7.25 21Z"
      stroke="#000000"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
    />
  </svg>
);
