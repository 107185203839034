// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notistack-MuiContent.notistack-MuiContent-default {
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  width: 389px;
  height: 115px;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.8);
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeSmall {
  position: absolute;
  top: 6px;
  right: 10px;
}

.snackbar-container {
  display: flex;
  gap: 8px;
}
.snackbar-container .snackbar-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 75px;
  height: 75px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.snackbar-container .snackbar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.snackbar-container .snackbar-content__status {
  display: flex;
  font-weight: 600;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/features/AddItemToCartPopup/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,sDAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;AACF;;AAOA;EACE,aAAA;EACA,QAAA;AAJF;AAME;EACE,wBAAA;EACA,4BAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,mBAAA;EACA,oCAAA;AAJJ;AAOE;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;AALJ;AAOI;EACE,aAAA;EACA,gBAAA;EACA,eAAA;AALN","sourcesContent":[".notistack-MuiContent.notistack-MuiContent-default {\n  transform: none;\n  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);\n  width: 389px;\n  height: 115px;\n  border-radius: 25px;\n  background: rgba(0, 0, 0, 0.8);\n}\n\n.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeSmall {\n  position: absolute;\n  top: 6px;\n  right: 10px;\n}\n\n.notistack-SnackbarContainer {\n  //position: absolute;\n  //top: 42px;\n}\n\n.snackbar-container {\n  display: flex;\n  gap: 8px;\n\n  & .snackbar-image {\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: center;\n    width: 75px;\n    height: 75px;\n    border: 1px solid rgb(204, 204, 204);\n    border-radius: 10px;\n    background-color: rgb(255, 255, 255);\n  }\n\n  & .snackbar-content {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n\n    &__status {\n      display: flex;\n      font-weight: 600;\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
