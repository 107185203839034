import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RoundedButton } from '../../shared/RoundedButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MinimalisticProductCard } from '../MinimalisticProductCard';
import { formatPrice } from '../../shared/libs/helpers';

import './index.scss';

export const CartModalForm = ({ showCartPanel, setShowCartPanel }) => {
  const navigate = useNavigate();
  const cartStore = useSelector((state) => state.shop.cart);
  const userStore = useSelector((state) => state.user.user);
  const popularItems = useSelector((state) => state.shop.popularItems);
  const [showAllItems, setShowAllItems] = useState(false);
  const [discount, setDiscount] = useState(0);

  const toggleDrawer = (newOpen) => () => setShowCartPanel(newOpen);

  const showMoreItemsHandler = () => {
    setShowAllItems(true);
  };

  const makeOrderHandler = () => {
    navigate(`/placing-order/123232xasdw2`);
  };

  const items = cartStore?.items || [];
  const visibleItems = showAllItems ? items : items.slice(0, 4);

  const productsCost = items.reduce(
    (total, product) =>
      total + (product.unit_price || 0) * (product.quantity || 1),
    0
  );
  const totalCost = productsCost - discount;

  useEffect(() => {
    if (userStore?.id) {
      setDiscount(productsCost * 0.05);
    }
  }, [productsCost, userStore]);

  return (
    <Drawer
      open={showCartPanel}
      anchor="right"
      className="cart-drawer"
      onClose={toggleDrawer(false)}
    >
      <Box role="presentation" className="cart-drawer-container">
        <Typography className="cart-title">Корзина</Typography>

        {items.length === 0 ? (
          <Typography
            className="empty-cart-message content-container"
            fontSize="20px"
            fontWeight="500"
            textAlign="center"
          >
            Корзина пуста
          </Typography>
        ) : (
          <div className="content-container">
            <div className="cart-item-list">
              {visibleItems.map((product) => (
                <MinimalisticProductCard
                  key={product.id}
                  product={product}
                  xs={6}
                  isVariant
                  cart={cartStore}
                />
              ))}
            </div>

            {items.length > 4 && !showAllItems && (
              <Typography
                className="cart__show-more"
                onClick={showMoreItemsHandler}
                style={{ cursor: 'pointer' }}
              >
                Еще товары в корзине ↓
              </Typography>
            )}

            <div className="card-total-cost">
              <Typography fontSize="30px" fontWeight="600" marginBottom="10px">
                Сумма заказа
              </Typography>
              <div className="cost-container">
                <Typography fontSize="14px" fontWeight="600">
                  Стоимость продуктов
                </Typography>
                <Typography fontSize="14px" fontWeight="600">
                  {formatPrice(productsCost)}
                </Typography>
              </div>
              <hr />
              <div className="cost-container">
                <Typography fontSize="14px" fontWeight="600">
                  Скидка
                </Typography>
                <Typography
                  className="discount"
                  fontSize="14px"
                  fontWeight="600"
                >
                  -{formatPrice(discount)}
                </Typography>
              </div>
              <hr />
              <div className="cost-container">
                <Typography fontSize="14px" fontWeight="600">
                  Итог
                </Typography>
                <Typography fontSize="30px" fontWeight="600">
                  {formatPrice(totalCost)}
                </Typography>
              </div>
              <RoundedButton className="order-btn" onClick={makeOrderHandler}>
                Оформить заказ
              </RoundedButton>
            </div>
          </div>
        )}

        <div className="cart-popular-items">
          <Typography fontSize="30px" fontWeight="600">
            Вам может понравиться
          </Typography>
          <div className="popular-items-list">
            {popularItems?.products?.length
              ? popularItems?.products?.map((product, index) => (
                  <MinimalisticProductCard
                    key={index}
                    product={product}
                    xs={6}
                  />
                ))
              : null}
          </div>
        </div>
      </Box>
    </Drawer>
  );
};
