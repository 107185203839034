// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.discount-cap {
  background: rgb(0, 237, 81);
  border-radius: 60px;
  max-width: 1440px;
  margin: 0 auto 10px;
  width: 100%;
  padding: 10px;
  text-align: center;
  justify-content: center;
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
}
.discount-cap__content {
  text-align: center;
  justify-content: center;
  display: flex;
  gap: 20px;
  align-items: center;
}
.discount-cap__text {
  font-size: 25px;
  font-weight: 600;
}
.discount-cap__icon img {
  height: 40px;
}
.discount-cap__close-btn {
  position: absolute;
  right: 20px;
}
.discount-cap__close-btn:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/features/DiscountHeaderCapForm/index.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AACJ;AACE;EACE,eAAA;EACA,gBAAA;AACJ;AAGI;EACE,YAAA;AADN;AAKE;EACE,kBAAA;EACA,WAAA;AAHJ;AAII;EACE,eAAA;AAFN","sourcesContent":[".discount-cap {\n  background: rgb(0, 237, 81);\n  border-radius: 60px;\n  max-width: 1440px;\n  margin: 0 auto 10px;\n  width: 100%;\n  padding: 10px;\n  text-align: center;\n  justify-content: center;\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  position: relative;\n\n  &__content {\n    text-align: center;\n    justify-content: center;\n    display: flex;\n    gap: 20px;\n    align-items: center;\n  }\n  &__text {\n    font-size: 25px;\n    font-weight: 600;\n  }\n\n  &__icon {\n    & img {\n      height: 40px;\n    }\n  }\n\n  &__close-btn {\n    position: absolute;\n    right: 20px;\n    &:hover {\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
