import { useCallback, useEffect, useState } from 'react';
import { getRequest } from './index';

export const useProducts = (params) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [trigger, setTrigger] = useState(0); // Для перезапуска useEffect

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    try {
      // const response = await getRequest("/store/filter?limit=0&offset=0&name=text", {
      const response = await getRequest('/store/products', {
        fields: `*variants.calculated_price`,
        country_code: 'RU',
        ...params,
      });
      setProducts(response);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    fetchProducts();
  }, [trigger]);

  const refetch = () => setTrigger((prev) => prev + 1);

  const loadMore = async (offset, limit) => {
    try {
      const response = await getRequest('/store/products', {
        fields: `*variants.calculated_price`,
        country_code: 'RU',
        offset,
        limit,
        ...params,
      });
      setProducts((prev) => ({
        ...prev,
        products: [...prev?.products, ...response?.products],
      }));
    } catch (err) {
      setError(err);
    }
  };

  return { products, loading, error, refetch, loadMore };
};

export const getFilteredProduct = async (params) => {
  return await getRequest('/store/products', {
    fields: `*variants.calculated_price`,
    country_code: 'RU',
    ...params,
  });
};

export const getProductById = async (productId, params) => {
  return await getRequest(`/store/products/${productId}`, {
    fields: `*variants.calculated_price, +metadata`,
    country_code: 'RU',
    ...params,
  });
};

export const fetchFilteredProducts = async (filter) => {
  const { products } = await getRequest(`/store/filter?${filter}`);
  return products;
};
