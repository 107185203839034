import React from 'react';
import { CatalogForm } from '../../widgets/CatalogForm';
import { useParams } from 'react-router-dom';
import { DefaultPageContainer } from '../DefaultPageContainer';

export const CatalogPage = () => {
  const { title, catalogId } = useParams();
  return (
    <DefaultPageContainer
      children={<CatalogForm catalogId={catalogId} />}
      title={title}
    />
  );
};
