// import React, { useEffect, useState } from "react";
// import {
//   Paper,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
//   Tabs,
// } from "@mui/material";
// import { useParams } from "react-router-dom";
// import { RoundedButton } from "../../shared/RoundedButton";
// import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
// import Box from "@mui/material/Box";
// import { getProductById } from "../../shared/libs/requests/product";
// import { formatPrice } from "../../shared/libs/helpers";
//
// import "./index.scss";
//
// export const SelectedProductForm = () => {
//   let { prodId } = useParams();
//   const [selectedProduct, setSelectedProduct] = useState({});
//   const [value, setValue] = useState(0);
//
//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };
//
//   function CustomTabPanel(props) {
//     const { children, value, index, ...other } = props;
//
//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`simple-tabpanel-${index}`}
//         aria-labelledby={`simple-tab-${index}`}
//         {...other}
//       >
//         {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//       </div>
//     );
//   }
//
//   function a11yProps(index) {
//     return {
//       id: `simple-tab-${index}`,
//       "aria-controls": `simple-tabpanel-${index}`,
//     };
//   }
//
//   function createData(name, value) {
//     return { name, value };
//   }
//
//   const rows = [
//     createData("Тип продукта", selectedProduct?.metadata?.productType),
//     createData("Для кого", selectedProduct?.metadata?.clientType),
//     createData("Страна производства", selectedProduct?.metadata?.brand),
//     createData("Назначение", selectedProduct?.metadata?.purpose),
//     createData("Количество", selectedProduct?.metadata?.count),
//     createData("Возраст", selectedProduct?.metadata?.age),
//   ];
//
//   const price = (el) =>
//     el?.calculated_price?.calculated_amount
//       ? formatPrice(el?.calculated_price.calculated_amount)
//       : "N/A";
//
//   useEffect(() => {
//     if (!selectedProduct?.id) {
//       const fetchProduct = async () => {
//         const { product } = await getProductById(prodId);
//         setSelectedProduct(product);
//       };
//       fetchProduct();
//     }
//   }, [selectedProduct?.id]);
//
//   return (
//     <div className="main-container">
//       <div className="product-container">
//         <img
//           className="product-container__img"
//           src={selectedProduct?.images?.find(el => el.url)?.url}
//           alt={selectedProduct?.title}
//         />
//         <div className="product-details">
//           <p className="product-details__description">
//             {selectedProduct?.description}
//           </p>
//           <p className="product-details__title">{selectedProduct?.title}</p>
//           <div className="product-details__volume-box">
//             <div className="volume-container">
//               <span>{selectedProduct?.variants?.[0]?.title}</span>
//             </div>
//             <p>
//               <b>{selectedProduct?.metadata?.gender}</b>
//             </p>
//           </div>
//           <span className="product-details__price">
//             {selectedProduct.variants?.map((variant) => (
//               <li key={variant.id}>{price(variant)}</li>
//             ))}
//           </span>
//           <RoundedButton className="product-details__cart">
//             <ShoppingBasketIcon />
//             Добавить в корзину
//           </RoundedButton>
//         </div>
//       </div>
//       <div className="product-tab-info">
//         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//           <Tabs
//             value={value}
//             onChange={handleChange}
//             aria-label="basic tabs example"
//           >
//             <Tab label="Описание" {...a11yProps(0)} />
//             <Tab label="Бренд" {...a11yProps(1)} />
//             <Tab label="Способ применения" {...a11yProps(2)} />
//             <Tab label="Состав" {...a11yProps(2)} />
//           </Tabs>
//         </Box>
//         <CustomTabPanel value={value} index={0} style={{ maxWidth: '800px' }}>
//           <h3>{selectedProduct.title}</h3>
//           <p>{selectedProduct.description}</p>
//           <TableContainer className="description-table" component={Paper} sx={{ marginTop: "40px" }}>
//             <Table sx={{ minWidth: 650 }} aria-label="simple table">
//               <TableBody>
//                 {rows.map((row) => (
//                   <TableRow
//                     key={row.name}
//                     sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                   >
//                     <TableCell component="th" scope="row">
//                       {row.name}
//                     </TableCell>
//                     <TableCell align="right">{row.value}</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={1}>
//           <p>{selectedProduct.metadata?.brand}</p>
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={2}>
//           <p>{selectedProduct.metadata?.notes?.base}</p>
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={3}>
//           <p>{selectedProduct.metadata?.notes?.upper}</p>
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={4}>
//           <p>{selectedProduct.metadata?.notes?.middle}</p>
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={5}>
//           <p>{selectedProduct.metadata?.gender}</p>
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={6}>
//           <p>{selectedProduct.metadata?.year_created}</p>
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={7}>
//           <p>{selectedProduct.metadata?.parfumer}</p>
//         </CustomTabPanel>
//       </div>
//       {/*<div>*/}
//       {/*  <p>Похожие товары</p>*/}
//       {/*  <Grid container spacing={4}>*/}
//       {/*    {products?.map((product) => (*/}
//       {/*      <CatalogProductCard key={product.id} product={product} xs={3} />*/}
//       {/*    ))}*/}
//       {/*  </Grid>*/}
//       {/*</div>*/}
//     </div>
//   );
// };

import React, { useEffect, useState } from 'react';
import {
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Skeleton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { RoundedButton } from '../../shared/RoundedButton';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Box from '@mui/material/Box';
import { getProductById } from '../../shared/libs/requests/product';
import { formatPrice } from '../../shared/libs/helpers';
import Typography from '@mui/material/Typography';
import { AddItemCalculateButton } from '../AddItemCalculateButton';

import './index.scss';

export const SelectedProductForm = () => {
  const { prodId } = useParams();
  const [selectedProduct, setSelectedProduct] = useState(null); // null to indicate loading
  const [value, setValue] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const metadataFields = [
    { key: 'productType', label: 'Тип продукта' },
    { key: 'clientType', label: 'Для кого' },
    { key: 'brand', label: 'Страна производства' },
    { key: 'purpose', label: 'Назначение' },
    { key: 'count', label: 'Количество' },
    { key: 'age', label: 'Возраст' },
  ];

  const price = (variant) =>
    variant?.calculated_price?.calculated_amount
      ? formatPrice(variant.calculated_price.calculated_amount)
      : 'N/A';

  useEffect(() => {
    const fetchProduct = async () => {
      const { product } = await getProductById(prodId);
      setSelectedProduct(product);
    };
    fetchProduct();
  }, [prodId]);

  return (
    <div className="main-container">
      <div className="product-container">
        {selectedProduct ? (
          <img
            className="product-container__img"
            src={
              selectedProduct.images?.[0]?.url ||
              'https://via.placeholder.com/150'
            }
            alt={selectedProduct.title || 'Product Image'}
          />
        ) : (
          <Skeleton
            variant="rectangular"
            width={300}
            height={300}
            className="product-container__img"
          />
        )}
        <div className="product-details">
          <p className="product-details__description">
            {selectedProduct?.description || <Skeleton width="80%" />}
          </p>
          <p className="product-details__title">
            {selectedProduct?.title || <Skeleton width="40%" />}
          </p>
          <div className="product-details__volume-box">
            <div className="volume-container">
              {selectedProduct?.variants ? (
                selectedProduct.variants.map((variant) => (
                  <span key={variant.id}>{variant.title}</span>
                ))
              ) : (
                <Skeleton width="60%" />
              )}
            </div>
            <p>
              <b>
                {selectedProduct?.metadata?.gender || <Skeleton width="20%" />}
              </b>
            </p>
          </div>
          <span className="product-details__price">
            {selectedProduct?.variants ? (
              selectedProduct.variants.map((variant) => (
                <li key={variant.id}>{price(variant)}</li>
              ))
            ) : (
              <Skeleton width="30%" />
            )}
          </span>
          {/*<AddItemCalculateButton*/}
          {/*  itemQuantity={quantity}*/}
          {/*  onChangeQuantity={updateQuantity}*/}
          {/*  buttonText={getItemPrice}*/}
          {/*  setItemQuantity={setQuantity}*/}
          {/*/>*/}
          <RoundedButton
            className="product-details__cart"
            disabled={!selectedProduct}
          >
            <ShoppingBasketIcon />
            Добавить в корзину
          </RoundedButton>
        </div>
      </div>
      <div className="product-tab-info">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="product details tabs"
          >
            <Tab label="Описание" />
            <Tab label="Бренд" />
            <Tab label="Способ применения" />
            <Tab label="Состав" />
          </Tabs>
        </Box>
        <div style={{ minHeight: '600px', margin: '30px 0' }}>
          {value === 0 && (
            <>
              <Typography className="item-tab__title">
                {selectedProduct?.title}
              </Typography>
              <Typography className="item-tab__description">
                {selectedProduct?.description}
              </Typography>
              <Typography variant="p" fontWeight="600">
                Подробные характеристики
              </Typography>
              <TableContainer
                component={Paper}
                className="description-table"
                sx={{ marginTop: '20px', maxWidth: 800 }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="product details table"
                >
                  <TableBody>
                    {metadataFields.map(({ key, label }) => (
                      <TableRow
                        key={key}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {label}
                        </TableCell>
                        <TableCell align="right">
                          {selectedProduct?.metadata?.[key] || 'N/A'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {value === 1 && (
            <Typography variant="p" fontWeight="600">
              {selectedProduct?.metadata?.brand || 'N/A'}
            </Typography>
          )}
          {value === 2 && (
            <Typography variant="p" fontWeight="600">
              {selectedProduct?.metadata?.notes?.base || 'N/A'}
            </Typography>
          )}
          {value === 3 && (
            <Typography variant="p" fontWeight="600">
              {selectedProduct?.metadata?.notes?.upper || 'N/A'}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
