import React from 'react';
import { Slider } from '@mui/material';
import PropTypes, { number } from 'prop-types';
import { formatPrice } from '../libs/helpers';

import './index.scss';

export const PriceSlider = ({
  filterDefaultValues,
  filterValues,
  onChangeValues,
}) => {
  const handleChange = (event, newValue) => onChangeValues(newValue);

  return (
    <div className="price-slider">
      <div className="price-slider__side-values">
        <span>от {formatPrice(filterValues[0] || filterDefaultValues[0])}</span>
        <span>до {formatPrice(filterValues[1] || filterDefaultValues[1])}</span>
      </div>
      <Slider
        getAriaLabel={() => 'Price range'}
        value={filterValues}
        min={filterDefaultValues[0]}
        max={filterDefaultValues[1]}
        onChange={handleChange}
        valueLabelDisplay="auto"
      />
    </div>
  );
};

PriceSlider.propTypes = {
  filterDefaultValues: PropTypes.arrayOf(number).isRequired,
  filterValues: PropTypes.arrayOf(number).isRequired,
  onChangeValues: PropTypes.func.isRequired,
};
