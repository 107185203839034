import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { RoundedButton } from '../../shared/RoundedButton';
import { useNavigate } from 'react-router-dom';
import { getProductCategories } from '../../shared/libs/requests/categories';
import { useDispatch } from 'react-redux';

import './index.scss';

export const HeaderCatalogForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewportHeight = window.innerHeight;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getProductCategories();

        setCategories(categories);
        dispatch(setCategories(categories));
      } catch (error) {
        console.error('Failed to fetch categories', error);
      }
    };

    fetchCategories();
  }, []);

  const toggleDrawer = (newOpen) => () => setOpenDrawer(newOpen);

  const onCatalogNavigate = (catalog) => {
    setOpenDrawer(false);
    navigate(`/catalog/${catalog?.name.toLowerCase()}/${catalog?.id}`);
  };

  return (
    <div className="header-catalog-form">
      <RoundedButton className="filter-btn" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </RoundedButton>
      <Drawer
        open={openDrawer}
        className="filter-drawer"
        onClose={toggleDrawer(false)}
      >
        <Box
          style={{ height: `${viewportHeight - 48}px` }}
          role="presentation"
          className="filter-drawer-container"
          sx={{ width: '348px', gap: 'unset' }}
        >
          <div
            className="filter-drawer__title-box"
            style={{ margin: '20px 0' }}
          >
            <div className="filter-reset-button">
              <span className="main-title">Каталог</span>
            </div>
            <Button onClick={toggleDrawer(false)}>
              <CloseIcon />
            </Button>
          </div>
          {categories?.length
            ? categories.map((item) => (
                <span
                  className="catalog-main-category"
                  key={item.id}
                  onClick={() => onCatalogNavigate(item)}
                >
                  {item?.name}
                </span>
              ))
            : null}
        </Box>
      </Drawer>
    </div>
  );
};
