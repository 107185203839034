export const IconContainer = () => (
  <svg
    width="27.003906"
    height="28.312988"
    viewBox="0 0 27.0039 28.313"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      id="Vector"
      d="M18.1 19.41L20.73 22.05L26 16.78"
      stroke="black"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      id="Vector"
      d="M24.68 11.52L24.68 8.89C24.68 8.43 24.56 7.97 24.33 7.57C24.09 7.17 23.76 6.84 23.36 6.61L14.15 1.35C13.75 1.12 13.3 1 12.84 1C12.37 1 11.92 1.12 11.52 1.35L2.31 6.61C1.91 6.84 1.58 7.17 1.35 7.57C1.12 7.97 1 8.43 1 8.89L1 19.41C1 19.87 1.12 20.33 1.35 20.73C1.58 21.13 1.91 21.46 2.31 21.69L11.52 26.95C11.92 27.18 12.37 27.31 12.84 27.31C13.3 27.31 13.75 27.18 14.15 26.95L16.78 25.45"
      stroke="black"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      id="Vector"
      d="M6.92 3.98L18.76 10.76"
      stroke="black"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      id="Vector"
      d="M1.37 7.57L12.84 14.15L24.3 7.57"
      stroke="black"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      id="Vector"
      d="M12.84 27.31L12.84 14.15"
      stroke="black"
      strokeOpacity="1.000000"
      strokeWidth="2.000000"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
);
