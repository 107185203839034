import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainPage } from './MainPage';
import { ProductPage } from './ProductPage';
import { CatalogPage } from './CatalogPage';
import { ProfilePage } from './ProfilePage';
import { NotFoundPage } from './NotFoundPage';
import { PlacingOrderPage } from './PlacingOrderPage';
import { PdfReadersPage } from './PdfReadersPage';
import { useSelector } from 'react-redux';

export const Routing = () => {
  const userStore = useSelector((state) => state.user.user);
  const cartStore = useSelector((state) => state.shop.cart);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/product/:prodId" element={<ProductPage />} />
        <Route path="/catalog/:title/:catalogId" element={<CatalogPage />} />
        {userStore?.id ? (
          <Route path="/profile/:tabId" element={<ProfilePage />} />
        ) : null}
        {userStore?.id ? (
          <Route path="/profile/:tabId/:panelId" element={<ProfilePage />} />
        ) : null}
        {userStore?.id ? (
          <Route path="/profile/" element={<ProfilePage />} />
        ) : null}
        {cartStore?.id ? (
          <Route
            path="/placing-order/:orderListId"
            element={<PlacingOrderPage />}
          />
        ) : null}
        <Route
          path="/privacy-policy"
          element={<PdfReadersPage fileName="privacy-policy" />}
        />
        <Route
          path="/data-processing-consent"
          element={<PdfReadersPage fileName="data-processing-consent" />}
        />
        <Route
          path="/user-agreement"
          element={<PdfReadersPage fileName="user-agreement" />}
        />
        <Route
          path="/proper-quality-product-refusal"
          element={<PdfReadersPage fileName="proper-quality-product-refusal" />}
        />
        <Route
          path="/improper-quality-product-refusal"
          element={
            <PdfReadersPage fileName="improper-quality-product-refusal" />
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
