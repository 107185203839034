import React from 'react';

import Typography from '@mui/material/Typography';
import { Link, List, ListItem, ListItemText } from '@mui/material';

export const Paragraph04 = () => (
  <>
    <Typography variant="h4" gutterBottom fontSize="24px" fontWeight="600">
      Отказ от товара
    </Typography>

    <Typography variant="body1" paragraph>
      1. Заявление об отказе от товара надлежащего качества. Этот документ
      используется, когда покупатель хочет вернуть товар, который соответствует
      заявленным характеристикам, но больше не нужен. Возврат возможен при
      условии сохранения товарного вида, потребительских свойств, а также
      наличия документа, подтверждающего покупку.
    </Typography>

    <Link href="/proper-quality-product-refusal" target="_blank">
      Заявление об отказе от товара надлежащего качества
    </Link>

    <Typography variant="body1" paragraph>
      2. Заявление об отказе от товара ненадлежащего качества. Этот документ
      применяется, если в приобретённом товаре выявлены недостатки, которые
      делают его использование невозможным или ограниченным. Покупатель имеет
      право вернуть такой товар или потребовать его замену, устранения
      недостатков, либо возврата уплаченной суммы.
    </Typography>

    <Link href="/improper-quality-product-refusal" target="_blank">
      Заявление об отказе от товара ненадлежащего качества
    </Link>
  </>
);
