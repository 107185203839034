// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-button {
  border: 1px solid black !important;
  border-radius: 50px !important;
  min-width: auto !important;
  font-weight: 600 !important;
  text-transform: none !important;
  height: 34px;
  color: black !important;
}
.icon-button .MuiSvgIcon-root {
  padding: 0;
  fill: black;
  width: 16px;
  height: 16px;
}
.icon-button:hover {
  color: black;
}

.Mui-disabled {
  opacity: 0.2 !important;
  cursor: not-allowed !important;
}`, "",{"version":3,"sources":["webpack://./src/shared/RoundedButton/index.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,8BAAA;EACA,0BAAA;EACA,2BAAA;EACA,+BAAA;EACA,YAAA;EACA,uBAAA;AACF;AACE;EACE,UAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;AACJ;AAEE;EACE,YAAA;AAAJ;;AAIA;EACE,uBAAA;EACA,8BAAA;AADF","sourcesContent":[".icon-button {\n  border: 1px solid black !important;\n  border-radius: 50px !important;\n  min-width: auto !important;\n  font-weight: 600 !important;\n  text-transform: none !important;\n  height: 34px;\n  color: black !important;\n\n  & .MuiSvgIcon-root {\n    padding: 0;\n    fill: black;\n    width: 16px;\n    height: 16px;\n  }\n\n  &:hover {\n    color: black;\n  }\n}\n\n.Mui-disabled {\n  opacity: 0.2 !important;\n  cursor: not-allowed !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
