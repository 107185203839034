// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-user-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  margin-bottom: 164px;
  margin-top: 60px;
}
.profile-user-cards-container .user-card {
  display: flex;
  flex-direction: column;
  padding: 16px 30px;
  width: 400px;
  height: 250px;
  border-radius: 30px;
  background: linear-gradient(135deg, rgb(195, 235, 14) 0%, rgb(14, 235, 55) 100%);
  color: rgb(255, 255, 255);
}
.profile-user-cards-container .user-card p {
  font-size: 75px;
  font-weight: 600;
}
.profile-user-cards-container .user-card span {
  text-align: end;
  font-size: 24px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/features/ProfileUserCardsSection/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gFAAA;EAKA,yBAAA;AAHJ;AAII;EACE,eAAA;EACA,gBAAA;AAFN;AAII;EACE,eAAA;EACA,eAAA;EACA,gBAAA;AAFN","sourcesContent":[".profile-user-cards-container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  max-width: 500px;\n  margin-bottom: 164px;\n  margin-top: 60px;\n\n  & .user-card {\n    display: flex;\n    flex-direction: column;\n    padding: 16px 30px;\n    width: 400px;\n    height: 250px;\n    border-radius: 30px;\n    background: linear-gradient(\n      135deg,\n      rgb(195, 235, 14) 0%,\n      rgb(14, 235, 55) 100%\n    );\n    color: rgb(255, 255, 255);\n    & p {\n      font-size: 75px;\n      font-weight: 600;\n    }\n    & span {\n      text-align: end;\n      font-size: 24px;\n      font-weight: 500;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
