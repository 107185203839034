// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical {
  gap: 10px;
}

.horizontal {
  row-gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/features/CardSkeletonForm/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;;AACA;EACE,aAAA;AAEF","sourcesContent":[".vertical {\n  gap: 10px;\n}\n.horizontal {\n  row-gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
