import React, { useEffect, useState } from 'react';
import { Grid, Button, Box } from '@mui/material';
import { CatalogProductCard } from '../../features/CatalogProductCard';
import { CatalogProductFilter } from '../../features/CatalogProductFilter';
import { AddItemToCartPopup } from '../../features/AddItemToCartPopup';
import { CardSkeletonForm } from '../../features/CardSkeletonForm';
import { useProducts } from '../../shared/libs/requests/product';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartHandler } from '../../shared/libs/helpers';

import './index.scss';

export const CatalogForm = ({ catalogId }) => {
  const dispatch = useDispatch();
  const filteredProducts = useSelector((state) => state.shop.filteredProducts);

  const productLimit = 100;
  const {
    products: { products, count: totalCount },
    loading,
    error,
    refetch,
    loadMore,
  } = useProducts({
    category_id: catalogId,
    fields: `*variants.calculated_price`,
    country_code: 'RU',
    limit: productLimit,
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [addedProduct, setAddedProduct] = useState(null);
  const [cartId, setCartId] = useState(null);

  const actualProducts = filteredProducts.length ? filteredProducts : products;

  useEffect(() => {
    if (catalogId) refetch();
  }, [catalogId]);

  return (
    <div className="catalog-form main-container">
      <AddItemToCartPopup
        triggerSnackbar={showSnackbar}
        product={addedProduct}
      />
      <CatalogProductFilter />
      {actualProducts && actualProducts.length > 0 ? (
        <div className="padding-container">
          <Grid container spacing={2}>
            {actualProducts.map((product) => (
              <CatalogProductCard
                key={product.id}
                product={product}
                addToCartHandler={(productData) =>
                  addToCartHandler(
                    productData,
                    cartId,
                    setCartId,
                    setShowSnackbar,
                    setAddedProduct,
                    dispatch
                  )
                }
                xs={3}
              />
            ))}
          </Grid>
        </div>
      ) : (
        <CardSkeletonForm cardsCount={16} />
      )}
      {loading && <CardSkeletonForm cardsCount={16} />}
      {!loading && actualProducts?.length < totalCount && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            variant="contained"
            onClick={() => loadMore(actualProducts?.length, productLimit)}
            endIcon={<span style={{ transform: 'rotate(90deg)' }}>➔</span>}
          >
            Показать еще
          </Button>
        </Box>
      )}
    </div>
  );
};
