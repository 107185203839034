import React, { useEffect } from 'react';
import { MedusaProvider } from 'medusa-react';
import { QueryClient } from '@tanstack/react-query';
import { Routing } from '../pages';
import { createTheme, ThemeProvider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUnSavedAddress, setUser } from './userSlice';
import { getLocalItem } from '../shared/libs/localStorage';
import { addCustomerAddress } from '../shared/libs/requests/user';
import { saveUserInfo } from '../shared/libs/helpers';
import { setCart, setItemsAttributes } from './shopSlice';
import { useProducts } from '../shared/libs/requests/product';

import './index.css';

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});

const App = () => {
  const dispatch = useDispatch();
  const unSavedAddress = useSelector((state) => state.user.unSavedAddress);
  const userStore = useSelector((state) => state.user.user);
  const itemsAttributes = useSelector((state) => state.shop.itemsAttributes);
  const API_BASE_URL =
    process.env.REACT_APP_USE_MOCK_DATA === 'true'
      ? 'http://localhost:3001'
      : process.env.REACT_APP_MEDUSA_BACKEND_URL;

  //TODO: временное решение, запрашиваем конкретные поля по товарам для сборки уникальных атрибутов.
  const { products } = useProducts({
    fields: `*variants.calculated_price,origin_country,metadata`,
    country_code: 'RU',
  });

  // Сохраняет в store из LocalStorage
  useEffect(() => {
    try {
      const userStorage = getLocalItem('user');
      const cartStorage = getLocalItem('cart');

      if (userStorage) dispatch(setUser(userStorage));
      if (cartStorage) dispatch(setCart(cartStorage));
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (products?.products && !itemsAttributes?.maxPrice) {
      const attributes = {
        maxPrice: 0,
        brand: new Set(),
        subcategory: new Set(),
        gender: new Set(),
        country: new Set(),
        weight: new Set(),
        age_range: new Set(),
      };

      products.products.forEach((el) => {
        // Определяем цену и обновляем maxPrice
        const itemPrice =
          el?.variants?.[0]?.calculated_price?.calculated_amount || 0;
        if (attributes.maxPrice < itemPrice) {
          attributes.maxPrice = itemPrice;
        }

        // Обновляем множества для уникальных значений
        el?.metadata?.brand && attributes.brand.add(el.metadata.brand);
        el?.metadata?.subcategory &&
          attributes.subcategory.add(el.metadata.subcategory);
        el?.metadata?.gender && attributes.gender.add(el.metadata.gender);
        el?.metadata?.country && attributes.country.add(el.metadata.country);
        el?.metadata?.weight && attributes.weight.add(el.metadata.weight);
        el?.metadata?.age_range &&
          attributes.age_range.add(el.metadata.age_range);
      });

      // Конвертируем множества обратно в массивы для сохранения
      dispatch(
        setItemsAttributes({
          maxPrice: attributes.maxPrice,
          brand: {
            id: 'brand',
            title: 'бренд',
            list: Array.from(attributes.brand).map((item) => ({
              type: item,
              isActive: false,
            })),
          },
          subcategory: {
            id: 'subcategory',
            title: 'назначение',
            list: Array.from(attributes.subcategory).map((item) => ({
              type: item,
              isActive: false,
            })),
          },
          gender: {
            id: 'gender',
            title: 'для кого',
            list: Array.from(attributes.gender).map((item) => ({
              type: item,
              isActive: false,
            })),
          },
          country: {
            id: 'country',
            title: 'страна',
            list: Array.from(attributes.country).map((item) => ({
              type: item,
              isActive: false,
            })),
          },
          weight: {
            id: 'weight',
            title: 'количество',
            list: Array.from(attributes.weight).map((item) => ({
              type: item,
              isActive: false,
            })),
          },
          age_range: {
            id: 'age_range',
            title: 'возраст',
            list: Array.from(attributes.age_range).map((item) => ({
              type: item,
              isActive: false,
            })),
          },
        })
      );
    }
  }, [products, itemsAttributes, dispatch]);

  // Сохраняет введеный адрес юзера после авторизации/регистрации
  useEffect(() => {
    const saveUserAddress = async () => {
      try {
        const customer = await addCustomerAddress({
          ...unSavedAddress,
          is_default_billing: true,
          is_default_shipping: true,
        });
        dispatch(setUnSavedAddress(null));
        saveUserInfo(customer, dispatch);
      } catch (e) {
        console.error(e);
      }
    };
    if (unSavedAddress && userStore) saveUserAddress();
  }, [userStore]);

  return (
    <div className="App">
      <MedusaProvider
        queryClientProviderProps={{ client: queryClient }}
        baseUrl={API_BASE_URL}
        publishableApiKey={process.env.REACT_APP_PUBLISH_API_KEY}
      >
        <ThemeProvider theme={theme}>
          <Routing />
        </ThemeProvider>
      </MedusaProvider>
    </div>
  );
};

export default App;
