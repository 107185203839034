import { deleteRequest, postRequest } from './index';
import { tempRetrieveAuthToken } from './auth';

export const updateCustomer = async (data) => {
  const token = await tempRetrieveAuthToken();
  const { customer } = await postRequest('/store/customers/me', data, token);
  return customer;
};

export const addCustomerAddress = async (addressData) => {
  const token = await tempRetrieveAuthToken();
  const { customer } = await postRequest(
    '/store/customers/me/addresses',
    addressData,
    token
  );
  return customer;
};

export const updateCustomerAddress = async (addressId, updatedData) => {
  const token = await tempRetrieveAuthToken();
  const { customer } = await postRequest(
    `/store/customers/me/addresses/${addressId}`,
    updatedData,
    token
  );
  return customer;
};

export const deleteCustomerAddress = async (addressId) => {
  const token = await tempRetrieveAuthToken();

  return await deleteRequest(
    `/store/customers/me/addresses/${addressId}`,
    token
  );
};
