import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Paragraph01 } from './Paragraph01';
import { Paragraph02 } from './Paragraph02';
import { Paragraph03 } from './Paragraph03';
import { Paragraph04 } from './Paragraph04';
import { useParams } from 'react-router-dom';

import './index.scss';

const panelNames = {
  pan01: 'paymentTerms',
  pan02: 'deliveryTerms',
  pan03: 'exchangeAndReturnRules',
  pan04: 'goodsRefusal',
};

const panels = [
  { name: 'paymentTerms', panel: <Paragraph01 /> },
  { name: 'deliveryTerms', panel: <Paragraph02 /> },
  { name: 'exchangeAndReturnRules', panel: <Paragraph03 /> },
  { name: 'goodsRefusal', panel: <Paragraph04 /> },
];

export const ProfileUserFAQSection = () => {
  const { panelId } = useParams();

  const [selectedPanel, setSelectedPanel] = useState(
    panelId ? panels.find((el) => el.name === panelId) : panels[0]
  );

  const setPanelHandler = (name) =>
    setSelectedPanel(panels?.find((el) => el.name === name));

  return (
    <div className="profile-user-faq-container">
      <div className="user-faq-navbar-container">
        <Typography onClick={() => setPanelHandler(panelNames.pan01)}>
          Условия оплаты
        </Typography>
        <Typography onClick={() => setPanelHandler(panelNames.pan02)}>
          Условия доставки
        </Typography>
        <Typography onClick={() => setPanelHandler(panelNames.pan03)}>
          Правила обмена и возврата товаров
        </Typography>
        <Typography onClick={() => setPanelHandler(panelNames.pan04)}>
          Отказ от товара
        </Typography>
      </div>
      <div className="user-faq-content-container">{selectedPanel.panel}</div>
    </div>
  );
};
